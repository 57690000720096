<template>
  <div id="EditPerson">
    <b-row>
      <b-col sm="8">
        <div class="card">
          <div slot="header" class="card-header">
            <i class="fa fa-edit"></i>
            <span v-if="$route.params.id == '-1'">Person anlegen</span>
            <span v-else>Person bearbeiten</span>
          </div>
          <div v-if="person" class="card-body">
            <b-form v-on:submit.prevent="onSubmit">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <label for="firstname">Vorname</label>
                    <b-form-input type="text" id="firstname" v-model="person.firstname"
                                  placeholder="Vorname"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="lastname">Nachname</label>
                    <b-form-input type="text" id="lastname" v-model="person.lastname"
                                  placeholder="Nachname"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="jobTitle">Berufsbezeichnung</label>
                    <b-form-input type="text" id="jobTitle" v-model="person.jobTitle"
                                  placeholder="Berufsbezeichnung"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col md="6">
                  <b-form-group>
                    <label>Bild</label><br>
                    <div style="margin-bottom: 10px">
                      <img v-if="person.image && person.image.id > 0"
                           v-bind:src="$store.state.mediaServiceUrl + person.image.uid + '/200/200'"
                           style="width: auto; height: auto; max-width: 200px; max-height: 200px;">
                    </div>
                    <b-button size="sm" @click="openMediaSelection('image')" variant="dark"><i class="fa"></i>Bild
                      auswählen/ändern
                    </b-button>&nbsp;
                    <a class="fa fa-trash fa-lg text-primary" style="cursor:pointer"
                       v-if="person.image && person.image.id" @click="deleteMedia('image')"></a>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label for="description">Beschreibung</label>
                    <quill-editor id="description" v-model="person.description"
                                  :options="customToolbarText"></quill-editor>
                  </b-form-group>

                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <label><b>Links (Social Media)</b></label>
                  <b-row v-for="(link, index) in person.links" :key="index">
                    <b-col md="4">
                      <b-form-group>
                        <label :for="'linkText' +index">Text</label>
                        <b-form-input type="text" :id="'linkText' + index" v-model="link.text"
                                      placeholder="Text"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group>
                        <label :for="'linkUrl' +index">Url</label>
                        <b-form-input type="text" :id="'linkUrl' + index" v-model="link.url"
                                      placeholder="Url"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label :for="'linkTarget' +index">Target</label>
                        <b-form-select v-model="link.target" :options="linkTargetOptions"
                                       value="_blank"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="1" style="margin-top: 30px;">
                      <a class="fa fa-trash fa-lg text-primary" style="cursor:pointer; vertical-align: bottom"
                         @click="removeLinkEle(index)"></a>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <b-button @click="addLinkEle()" style="margin-top: 2px; vertical-align: bottom" size="sm"
                                variant="dark"><i class="fa fa-plus"></i> Link hinzufügen
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <br>
              <div class="footer-btn" slot="footer" style="margin-top: 10px;">
                <b-button type="submit" stacked size="sm" variant="primary"><i class="fa fa-save"></i> Speichern
                </b-button>&nbsp;
                <b-button size="sm" href="/#/blog/persons/" variant="danger"><i class="fa fa-ban"></i> Abbrechen
                </b-button>
              </div>
            </b-form>
          </div>
          <br>
        </div>
      </b-col>
      <MediaSelection
        :show="showMediaSelection"
        :type="mediaType"
        :mime-types="'image/jpeg,image/png'"
        @close="showMediaSelection = false"
        @save="saveMedia($event)"
      />
    </b-row>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import VueNotifications from 'vue-notifications'
  import {quillEditor} from 'vue-quill-editor'
  import MediaSelection from '../Media/MediaSelection'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'

  export default {
    components: {
      quillEditor,
      MediaSelection,
    },
    name: 'editPerson',
    data() {
      return {
        showMediaSelection: false,
        mediaType: '',
        linkTargetOptions: [{value: '_blank', text: '_blank'}, {value: '_self', text: '_self'}],
        customToolbarText: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
            ]
          }
        },
      }
    },
    computed: {
      ...mapGetters({
        person: 'person/getPerson',
        error: 'person/getError'
      })
    },
    mounted() {
      if (this.$route.params.id != '-1') {
        this.$store.dispatch('person/loadPerson', this.$route.params.id)
      } else {
        this.$store.dispatch('person/initPerson')
      }
    },
    methods: {
      onSubmit() {
        this.$store.dispatch('person/savePerson').then(
          response => {
            if (response.status == 200 || response.status == 201) {
              this.notifySaveSuccess()
            }
          }, error => {
            this.notifySaveError()
          }
        )
      },
      openMediaSelection: function (mediaType) {
        this.showMediaSelection = true
        this.mediaType = mediaType
      },
      saveMedia: function (payload) {
        this.person.image = {'id': payload.id, 'uid': payload.uid}
      },
      deleteMedia: function (mediaType) {
        this.person.image = undefined;
      },
      addLinkEle: function () {
        if (!this.person.links) {
          this.person.links = []
        }
        this.person.links.push({'text': '', 'url': '', 'target': '_blank'})
      },
      removeLinkEle: function (idx) {
        this.person.links.splice(idx, 1)
      }
    },

    notifications: {
      notifySaveSuccess: {
        type: VueNotifications.types.success,
        title: 'Person wurde erfolgreich gespeichert',
      },
      notifySaveError: {
        type: VueNotifications.types.error,
        title: 'Fehler beim speichern der Person'
      }
    }
  }
</script>
